import React from "react";
import { Grid, Paper, Typography, Hidden, Tabs, Tab, Card } from "@material-ui/core";
import DataForm from "../componets/DataForm";
import DataOutput from "../componets/DataOutput";
import { PRIMARY, PRIMARY_TRANSPARENT, COMPANY_LOGO, OH_LOGO } from "../config";
import SwipeableViews from 'react-swipeable-views'

class MainView extends React.Component {
    state = {
        selectedTab: 0
    }

    tabChanged = (event, value) => {
        this.setState({ selectedTab: value })
    }

    handleChangeIndex = index => {
        this.setState({ selectedTab: index })
    }

    render() {
        const dataOutputCall = <DataOutput type='Call'/>
        const dataOutputPut = <DataOutput type='Put'/>

        return (
            <div style={{
                backgroundColor: PRIMARY_TRANSPARENT,
                flexGrow: 1,
                overflow: 'visible'
            }} >
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Paper elevation={5} style={{ margin: '16px 16px 16px 16px', padding: 16, borderRadius: '30px' }}>
                                <Grid container spacing={16} justify="space-between">
                                
                                    <Grid item>
                                        <img src={COMPANY_LOGO} alt='' style={{ height: '60px', width: 'auto' }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' style={{ color: PRIMARY, position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)', transform: 'translatey(-50%)' }}>OptionMaster</Typography>
                                    </Grid>
                                
                                    <Grid item >
                                        <a  href="https://optionenhandeln.de/" target="_blank" rel="noopener noreferrer">
                                            <img src={OH_LOGO} alt='https://optionenhandeln.de/' style={{ height: '60px', width: 'auto' }} />
                                        </a>
                                    </Grid>
                                </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper elevation={5} style={{ margin: '0px 16px 0px 16px', padding: 16, borderRadius: '30px' }}>
                            <DataForm />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={5} style={{ margin: '0px 16px 0px 16px', padding: 16, borderRadius: '30px' }}>
                            <Hidden smUp>
                                <Grid item xs={12}>
                                    <Tabs
                                        value={this.state.selectedTab}
                                        onChange={this.tabChanged}
                                        indicatorColor='primary'
                                        textColor='primary'
                                        centered
                                        style={{ marginTop: -16, paddingBottom: 16 }}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: PRIMARY
                                            }
                                          }}
                                    >
                                        <Tab label="Call" />
                                        <Tab label="Put" />
                                    </Tabs>

                                </Grid>
                            </Hidden>
                            <Hidden xsDown>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        {dataOutputCall}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {dataOutputPut}
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden smUp>
                                <Grid container spacing={8}>
                                    <Grid item xs={12}>
                                        <SwipeableViews
                                            //axis='x'
                                            index={this.state.selectedTab}
                                            onChangeIndex={this.handleChangeIndex}
                                            disabled={true}
                                        >
                                            <DataOutput type='Call' style={{padding:2, width:'99%'}}/>
                                            <DataOutput type='Put' style={{padding:2, width:'99%', overflow: 'hidden'}}/>
                                        </SwipeableViews>
                                    </Grid>
                                </Grid>
                            </Hidden>

                        </Paper>
                        <Grid item xs={12}>

                        </Grid>
                    </Grid>

                </Grid >
            </div>
        )
    }
}

export default MainView