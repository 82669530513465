import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import MainView from './views/MainView';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './store/reducers/rootReducer';
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { PRIMARY, SECONDARY } from './config';
import { SnackbarProvider } from 'notistack'
import ReactGA from 'react-ga'
import CookieConsentPopUp from './componets/CookieConsentPopUp';

ReactGA.initialize('UA-140771558-1')
ReactGA.pageview('/option_master')

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
)

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
      },
    palette: {
        primary: {
            main: PRIMARY
        },
        secondary: {
            main: SECONDARY
        }
    }
})

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <SnackbarProvider maxSnack={1}>
                <MainView />
            </SnackbarProvider>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')

);

serviceWorker.unregister();
