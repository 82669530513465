import { SET_OPTION_INFORMATION, SET_GREEKS, SET_CALL_MATRIX, SET_PUT_MATRIX, SET_CALCULATE_MATRIX } from "../actions/optionDataActions";


const initState = {
    optionInformation: {
        days: 0,
        underlyingPrice: 0,
        strikePrice: 0,
        volatility: 0,
        interestRate: 0,
        callPrice: 0,
        putPrice: 0,
        daysDividend: 0,
        dividend: 0,
        callImpliedVolatility: 0,
        putImpliedVolatility: 0
    },
    greeks: {
        call: {
            delta: 0,
            theta: 0,
            gamma: 0,
            vega: 0,
            rho: 0,
            vanna: 0,
            charm: 0,
            vomma: 0,
            veta: 0,
            speed: 0,
            zomma: 0,
            color: 0
        },
        put: {
            delta: 0,
            theta: 0,
            gamma: 0,
            vega: 0,
            rho: 0,
            vanna: 0,
            charm: 0,
            vomma: 0,
            veta: 0,
            speed: 0,
            zomma: 0,
            color: 0
        }
    },
    calculateMatrix: false,
    callMatrix: [
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]
    ],
    putMatrix: [
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
        [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]
    ]
}

const optionDataReducer = (state=initState, action) => {
    switch(action.type) {
        case SET_OPTION_INFORMATION:
            return { ...state, optionInformation: action.payload}
        case SET_GREEKS:
            return { ...state, greeks: action.payload}
        case SET_CALL_MATRIX:
            return { ...state, callMatrix: action.payload}
        case SET_PUT_MATRIX:
            return { ...state, putMatrix: action.payload}
        case SET_CALCULATE_MATRIX:
            return { ...state, calculateMatrix: action.payload}
        default:
            return state
    }
}

export default optionDataReducer