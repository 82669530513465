import React, { Fragment } from "react"
import { Grid, Paper, Typography, Divider, FormControlLabel, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core"
import CustomSwitch from "../componets/CustomSwitch";
import DataTable from "./DataTable";
import PriceVolatilityMatrix from "./PriceVolatilityMatrix";
import { connect } from "react-redux";
import { SECONDARY_BACKGROUND, PRIMARY_BACKGROUND, SECONDARY, PRIMARY } from "../config";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



function CustomText(props) {
    const backgorundColor = props.color === 'secondary' ? SECONDARY_BACKGROUND : PRIMARY_BACKGROUND
    const color = props.color === 'secondary' ? SECONDARY : PRIMARY
    const name = props.name
    const value = (Math.round(props.value * 10000) / 10000).toFixed(4)

    return (
        <Grid container>
            <Grid item xs={12}>
                <div style={{ width: '100%', borderRadius: 3, background: backgorundColor }}>
                    <Typography style={{ paddingLeft: 8, color: color }}>{value}</Typography>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ paddingLeft: 8, color: color }} variant='caption'>{name}</Typography>
            </Grid>
        </Grid>
    )
}


class DataOutput extends React.Component {

    state = {
        graphDialogOpen: false,
        moreIndicatorsChecked: false,
    }

    handleCange = name => event => {
        switch (name) {
            case 'graph':
                this.setState({ graphDialogOpen: true })
                break
            default:
                break
        }
    }

    handleDialogClose = () => {
        this.setState({ graphDialogOpen: false })
    }

    handelSwitchChanged = name => event => {
        this.setState({ [name]: event.target.checked })
    }


    render() {
        const { type, greeks, optionInformation, style } = this.props

        var values = {}
        var optionPrice = 0
        var data = []

        var impliedVolatility = 0

        if (type === 'Call') {
            values = greeks.call
            optionPrice = optionInformation.callPrice
            data = this.props.callMatrix
            impliedVolatility = this.props.optionInformation.callImpliedVolatility * 100
            optionPrice = this.props.optionInformation.callPrice

        } else {
            values = greeks.put
            optionPrice = optionInformation.putPrice
            data = this.props.putMatrix
            impliedVolatility = this.props.optionInformation.putImpliedVolatility * 100
            optionPrice = this.props.optionInformation.putPrice
        }

        return (
                <Grid container spacing={8} style={style}>
                    <Grid item xs={12}>
                        <Typography variant='h6' style={{ color: PRIMARY }}>{type} Result</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomText name={`${type} Price`} value={optionPrice} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomText name='Implied Volatility' value={impliedVolatility} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6' style={{ color: PRIMARY }}>{type} Option Indicators</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomText color='primary' name='Delta' value={values.delta} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomText color='primary' name='Theta' value={values.theta} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomText color='secondary' name='Gamma' value={values.gamma} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomText color='secondary' name='Vega' value={values.vega} />
                    </Grid>
                    {this.state.moreIndicatorsChecked ? (
                        <Fragment>
                            <Grid item xs={6}>
                                <CustomText color='primary' name='Rho' value={values.rho} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomText color='primary' name='Vanna' value={values.vanna} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomText color='secondary' name='Charm' value={values.charm} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomText color='secondary' name='Vomma' value={values.vomma} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomText color='primary' name='Veta' value={values.veta} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomText color='primary' name='Speed' value={values.speed} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomText color='secondary' name='Zomma' value={values.zomma} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomText color='secondary' name='Color' value={values.color} />
                            </Grid>

                        </Fragment>
                    ) : (null)
                    }

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={this.state.moreIndicatorsChecked}
                                    onChange={this.handelSwitchChanged('moreIndicatorsChecked')}
                                    value='moreIndicatorsChecked'
                                />
                            }
                            label="Show All"
                        />
                    </Grid>
                    {(this.props.calculateMatrix ? (
                        <Fragment>
                            <Grid item xs={12}>
                                <Divider variant='middle' />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ color: PRIMARY }}>Price/Volatility Matrix</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <DataTable
                                        data={data}
                                        underlyingPrice={this.props.optionInformation.underlyingPrice}
                                        underlyingVolatility={this.props.optionInformation.volatility}
                                    />

                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant='h6' style={{ color: PRIMARY }}>Price/Volatility Graph</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <PriceVolatilityMatrix
                                            data={data}
                                            underlyingPrice={this.props.optionInformation.underlyingPrice}
                                            underlyingVolatility={this.props.optionInformation.volatility}
                                            optionPrice={optionPrice}
                                            type={type}
                                        />
                                    </ExpansionPanelDetails>

                                </ExpansionPanel>
                            </Grid>
                        </Fragment>
                    ) : null)}
                </Grid>

        )
    }
}

const mapStateToProps = state => {
    return {
        optionInformation: state.optionData.optionInformation,
        greeks: state.optionData.greeks,
        putMatrix: state.optionData.putMatrix,
        callMatrix: state.optionData.callMatrix,
        calculateMatrix: state.optionData.calculateMatrix
    }
}
export default connect(mapStateToProps, null)(DataOutput)