import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { SECONDARY } from '../config';

const styles = theme => ({
    colorSwitchBase: {
        '&$colorChecked': {
            color: SECONDARY,
            '& + $colorBar': {
                backgroundColor: SECONDARY,
            },
        },
    },
    colorBar: {},
    colorChecked: {},
});

function CustomSwitch(props) {
    const { classes, checked, onChange, value } = props;

    return (
        <Switch
            checked={checked}
            onChange={onChange}
            value={value}
            classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked,
                bar: classes.colorBar,
            }}
        />
    );
}

CustomSwitch.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomSwitch);