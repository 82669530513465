import gaussian from "gaussian"

// Option Calculator source Natenbert p.350
class OptionCalculator {

    constructor() {
        this.distribution = gaussian(0, 1)
    }

    calculateSpotPrice(S, r, q, td) {
        const T = td/365
        if (td !== 0) {
            return S - q * Math.exp(r*T)
        }
        return S
    }

    blackScholesPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)
        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        const putPrice = (X * Math.exp(-r * T) * this.distribution.cdf(-d2) - S_cal * Math.exp((b - r)*T) * this.distribution.cdf(-d1))
        return Math.max(putPrice, 0)
    }

    blackScholesCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        
        const callPrice = (S_cal * Math.exp((b-r) * T) * this.distribution.cdf(d1) - X * Math.exp(-r * T) * this.distribution.cdf(d2))
        return Math.max(callPrice, 0)
    }

    impliedVolatilityCall(S, X, t, r, q, p, td) {
        const MAX_ITER = 100000
        const ACC = 0.0001;

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const T = t/365
        var sigma = (p/S_cal)/(0.398*Math.sqrt(T))
        for (var i=0; i<MAX_ITER; i++) {
            const price = this.blackScholesCall(S,X,t,r,q,sigma, td)
            const diff = p-price
            if (Math.abs(diff) < ACC) return sigma
            const vega = this.vegaCall(S,X,t,r,q,sigma, td)*100;
            sigma = sigma + diff/vega;
        }
        return "Error, failed to converge";
    }

    impliedVolatilityPut(S, X, t, r, q, p, td) {
        const MAX_ITER = 100000
        const ACC = 0.0001;

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const T = t/365
        var sigma = (p/S_cal)/(0.398*Math.sqrt(T))
        for (var i=0; i<MAX_ITER; i++) {
            const price = this.blackScholesPut(S,X,t,r,q,sigma, td)
            const diff = p-price
            if (Math.abs(diff < ACC)) return sigma
            const vega = this.vegaPut(S,X,t,r,q,sigma, td)*100;
            sigma = sigma + diff/vega;
        }

        return "Error, failed to converge";
    }

    // Tested against Python
    deltaCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        return (Math.exp((b - r) * T) * this.distribution.cdf(d1))
    }

    // Tested against Python
    deltaPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        return (Math.exp((b - r) * T) * (this.distribution.cdf(d1) - 1))
    }

    // Tested against Python and OptionMaster
    gammaCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        return (Math.exp((b - r) * T) * this.distribution.pdf(d1)/(S*sigma*Math.sqrt(T)))
    }

    // Tested against Python and OptionMaster
    gammaPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        return this.gammaCall(S, X, t, r, q, sigma, td)
    }

    // Tested against Python and OptionMaster
    thetaCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        const theta = (-S_cal * Math.exp((b-r)*T) * this.distribution.pdf(d1) * sigma)/(2 * Math.sqrt(T)) - (b-r)*S_cal*Math.exp((b-r)*T) * this.distribution.cdf(d1) - r*X*Math.exp(-r*T) * this.distribution.cdf(d2)
        return theta/365
    }

    // Tested against Python and Option Master
    thetaPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        const theta = (-S_cal * Math.exp((b-r)*T) * this.distribution.pdf(d1) * sigma)/(2 * Math.sqrt(T)) - (b-r)*S_cal*Math.exp((b-r)*T) * this.distribution.cdf(d1) + r*X*Math.exp(-r*T) * this.distribution.cdf(-d2)
        return theta/365
    }

    // Teted against Python and OptionMaster
    vegaCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        const vega = S_cal*Math.exp((b-r)*T) * this.distribution.pdf(d1) * Math.sqrt(T)
        return vega/100
    }

    // Tested against Python and OptionMaster
    vegaPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        return this.vegaCall(S, X, t, r, q, sigma, td) 
    }

    // Tested against Python and OptionMaster
    rhoCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        
        var roh = null
        if (b === 0) {
            roh = -T*this.blackScholesCall(S, X, t, r, q, sigma, td)
        } else {
            roh = T*X*Math.exp(-r*T) * this.distribution.cdf(d2)
        }

        return roh/100
    }

    // Tested agains Python and OptionMaster
    rhoPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        
        var roh = null
        if (b === 0) {
            roh = -T*this.blackScholesPut(S, X, t, r, q, sigma, td)
        } else {
            roh = -T*X*Math.exp(-r*T) * this.distribution.cdf(-d2)
        }

        return roh/100
    }

    // Tested against Python
    elasticityCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        return this.gammaCall(S, X, t, r, q, sigma, td) * S_cal/this.blackScholesCall(S, X, t, r, q, sigma, td)
    }

    // Tested against Python
    elasticityPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        return this.gammaPut(S, X, t, r, q, sigma, td) * S_cal/this.blackScholesPut(S, X, t, r, q, sigma)
    }

    // Tested against Python
    vannaCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        return -Math.exp((b-r)*T) * this.distribution.pdf(d1) * d2/sigma
    }

    // Tested against Python
    vannaPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        return this.vannaCall(S, X, t, r, q, sigma, td)
    }

    // Tested against Python
    charmCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        const first = -Math.exp((b-r)*T)
        const second = this.distribution.pdf(d1)*((b/(sigma*Math.sqrt(T)) - d2/(2*T)) + (b-r)*this.distribution.cdf(d1))

        return first*second
    }

    // Tested against Python
    charmPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        const first = -Math.exp((b-r)*T)
        const second = this.distribution.pdf(d1)*((b/(sigma*Math.sqrt(T)) - d2/(2*T)) - (b-r)*this.distribution.cdf(d1))

        return first*second
    }

    // Tested against Python
    speedCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        return -this.gammaCall(S, X, t, r, q, sigma)/S * (1 + d1/(sigma*Math.sqrt(T)))
    }

    // Tested against Python
    speedPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        return this.speedCall(S, X, t, r, q, sigma, td)
    }

    // Tested against Python
    colorCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        return this.gammaCall(S, X, t, r, q, sigma) * (r-b+(b*d1)/(sigma*Math.sqrt(T)) + (1-d1*d2)/(2*T))
    }

    // Tested against Python
    colorPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        return this.colorCall(S, X, t, r, q, sigma, td)
    }

    // Tested against Python
    volgaCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T)) 

        return this.vegaCall(S, X, t, r, q, sigma, td) * (d1*d2)/sigma
    }

    // Tested against Python
    volgaPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        return this.volgaCall(S, X, t, r, q, sigma, td)
    }

    // Tested against Python
    vegaDecayCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        return this.vegaCall(S, X, t, r, q, sigma, td) * (r - b + (b*d1)/(sigma*Math.sqrt(T)) - (1 - d1*d2)/(2*T))
    }

    // Testd against Python
    vegaDecayPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        return this.vegaDecayCall(S, X, t, r, q, sigma, td)
    }

    // Tested against Python
    zommaCall(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        const T = t/365
        const b = r

        const S_cal = this.calculateSpotPrice(S, r, q, td)

        const d1 = (Math.log(S_cal / X) + (b + 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))
        const d2 = (Math.log(S_cal / X) + (b - 0.5 * sigma ** 2) * T) / (sigma * Math.sqrt(T))

        return this.gammaCall(S, X, t, r, q, sigma) * ((d1*d2 - 1)/sigma)
    }

    // Tested against Python
    zommaPut(S, X, t, r, q, sigma, td) {
        // S: spot price
        // X: strike price
        // t: time to maturity in days
        // r: interest rate
        // q: rate of continous dividend paying asset
        // sigma: volatility of underlying asset

        return this.zommaCall(S, X, t, r, q, sigma, td)
    }


}

export default OptionCalculator