import React from "react"
import { TextField, withStyles } from "@material-ui/core"
import { PRIMARY } from "../config";

const styles = theme => ({
    root: {
        width: '100%'
    },
    cssLabel: {
        //color: BLUE,
        '&$cssFocused': {
            color: PRIMARY,
        },
    },
    cssFocused: {},
    cssOutlinedInput: {
        // '& $notchedOutline': {
        //     borderColor: BLUE,
        //     },
        '&$cssFocused $notchedOutline': {
            borderColor: PRIMARY,
        },
    },
    notchedOutline: {},
});

function CustomTextField(props) {
    const { classes } = props
    return (
        <TextField
            id={props.id}
            variant='outlined'
            label={props.label}
            type={props.type}
            margin='dense'
            value={props.value}
            onChange={props.onChange}
            className={classes.root}
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                },
            }}
            InputProps={{
                classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                },
                endAdornment: props.endAdornment ? props.endAdornment : null
            }}
        />
    )
}


export default withStyles(styles)(CustomTextField)