export const SET_OPTION_INFORMATION = "SET_OPTION_INFORMATION"
export const SET_GREEKS = "SET_GREEKS"
export const SET_CALL_MATRIX = "SET_CALL_MATRIX"
export const SET_PUT_MATRIX = "SET_PUT_MATRIX"
export const SET_CALCULATE_MATRIX = "SET_CALCULATE_MATRIX"

export function setOptionInformation(optionInformation) {
    return function (dispatch) {
        return dispatch({
            type: SET_OPTION_INFORMATION,
            payload: optionInformation
        })
    }
}

export function setGreeks(greeks) {
    return function (dispatch) {
        dispatch({
            type: SET_GREEKS,
            payload: greeks
        })
    }
}

export function setCallMatrix(matrix) {
    return function (dispatch) {
        dispatch({
            type: SET_CALL_MATRIX,
            payload: matrix
        })
    }
}

export function setPutMatrix(matrix) {
    return function (dispatch) {
        dispatch({
            type: SET_PUT_MATRIX,
            payload: matrix
        })
    }
}

export function setCalculateMatrix(calculate) {
    return function (dispatch) {
        dispatch({
            type: SET_CALCULATE_MATRIX,
            payload: calculate
        })
    }
}